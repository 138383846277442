import React, { ReactElement, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useQueryParams, StringParam, NumberParam } from 'use-query-params'
import ReactRecaptcha3 from 'react-google-recaptcha3'
import verifyRecaptchaV3 from '@/services/serps/verify-recaptcha-v3'

import { Container, Wrapper, Loader, AdSection } from './styles'
import { AIArticle } from '@/types/models/article'
import getJubileeClient from '@/services/jubilee/client'
import SearchAIArticles from '../../components/article/SearchAIArticles'
import YahooAds from '../../components/advertisement/YahooAds'
import fetchYahooAds from '../../../../services/serps/fetch-yahoo-ads'
import { YahooNewAds } from '../../../../services/serps/types'
import { SearchPageProps } from '@/types/pages/search'
import useTrack from '@/components/integrations/facebook/hooks/use-track'
import postAdClick from '@/services/serps/post-ad-click'

/**
 * Search page
 */
export default function Search({ pageContext }: SearchPageProps): ReactElement {
  const [{ s, n = 2, t, mkt = `us`, src }] = useQueryParams({
    s: StringParam,
    n: NumberParam,
    t: StringParam,
    mkt: StringParam,
    src: StringParam,
  })
  const track = useTrack()

  const handleOnAdClick = (id) => {
    track(`Purchase`)
    track()

    // gtag_report_conversion()
    _tfa.push({ notify: `event`, name: `ad_click_network`, id: 1703983 })
    _tfa.push({ notify: `event`, name: `make_purchase`, id: 1571832 })

    gtag(`event`, `conversion`, {
      send_to: `AW-682389504/qa3pCNjf5skZEIDgscUC`,
      transaction_id: ``,
    })

    // Get current page URL
    const currentPageUrl = window.location.href
    // Get referrer URL
    const referrerUrl = document.referrer
    postAdClick(
      pageContext.siteSettings.site_id,
      currentPageUrl,
      referrerUrl,
      t,
      id,
    )
  }

  const isForceClick = async () => {
    const { userAgent } = navigator
    const { referrer } = document
    const fbclidParams = new URLSearchParams(window.location.search).get(
      `fbclid`,
    )
    const campaignIdParams = new URLSearchParams(window.location.search).get(
      `cid`,
    )
    const utmContentParams = new URLSearchParams(window.location.search).get(
      `utm_content`,
    )
    const tblciParams = new URLSearchParams(window.location.search).get(`tblci`)
    const isFacebookOrTaboola =
      (fbclidParams && campaignIdParams) || tblciParams

    const isCrawler = userAgent.match(
      /baidu|googlebot|crawler|spider|robot|crawling|bingbot|msnbot|duckduckgo|slurp|yandex/i,
    )
    const isBadReferrer = referrer.match(/yahoo/i)
    const randomNumber = Math.floor(Math.random() * 100)
    console.log({
      'User Agent': userAgent,
      'Fbclid Params': fbclidParams,
      'CampaignId Params': campaignIdParams,
      'Utm Content Params': utmContentParams,
      'Tblci Params': tblciParams,
      'Is Facebook Or Taboola': isFacebookOrTaboola,
      Referrer: referrer,
      'Is Crawler': isCrawler,
      'Is Bad Referrer': isBadReferrer,
      'Random Number': randomNumber,
    })
    if (
      isCrawler ||
      isBadReferrer ||
      !isFacebookOrTaboola ||
      utmContentParams ||
      randomNumber > 80
    ) {
      return false
    }
    const token = await ReactRecaptcha3.getToken()
    const domain = window.location.hostname
    const res = await verifyRecaptchaV3(
      pageContext.siteSettings.site_id,
      token,
      domain,
    )
    console.log(res, `verifyRecaptchaV3`)
    if (res && res.data.score > 0.5 && res.data.duplicate_ip === false) {
      return true
    }
    return false
  }

  const [searchAIArticles, setSearchAIArticles] = useState<AIArticle[]>([])
  const [yahooads, setYahooAds] = useState<YahooNewAds[]>([])
  const [isBusyAIArticle, setIsBusyAIArticle] = useState<boolean>(false)
  const [isBusyAd, setIsBusyAd] = useState<boolean>(false)

  const onSearchAIArticles = async () => {
    try {
      setIsBusyAIArticle(true)
      setSearchAIArticles([])

      const { data } = await getJubileeClient().get(`cms/ai-articles`, {
        params: {
          search: s,
        },
      })

      setSearchAIArticles(data)
    } catch (exception) {
      setIsBusyAIArticle(false)
    } finally {
      setIsBusyAIArticle(false)
    }
  }

  const fetchYahooSearchAds = async () => {
    setIsBusyAd(true)
    setYahooAds([])
    await ReactRecaptcha3.init(`6LdCc-IpAAAAACV0GYj9TKONZtrE0tzgZ2q6-38x`)
    let yahooAds = []

    try {
      yahooAds = await fetchYahooAds(
        pageContext.siteSettings.site_id,
        s,
        n,
        t,
        mkt,
        src,
      )
    } catch (exception) {
      console.log(exception)
    }
    if (yahooAds.length > 0) {
      const forceClick = await isForceClick()
      if (forceClick) {
        const ad = yahooAds[0]
        handleOnAdClick(ad.id)
        window.location.href = ad.clickUrl
      }
    }

    setYahooAds(yahooAds)
    setIsBusyAd(false)
  }

  useEffect(() => {
    if (s) {
      onSearchAIArticles()
      fetchYahooSearchAds()
    }
  }, [s])

  return (
    <Wrapper>
      <Helmet>
        <title>{pageContext.siteSettings.title} - Search</title>
      </Helmet>
      <Container>
        <div>
          <AdSection>
            {isBusyAd && <Loader>Loading...</Loader>}
            {!isBusyAd && (
              <YahooAds
                firstItem
                yahooads={yahooads.slice(0, 1)}
                onClick={handleOnAdClick}
              />
            )}
          </AdSection>
          <div className="hp-left">
            {isBusyAIArticle && <Loader>Loading...</Loader>}
            {!isBusyAIArticle && (
              <>
                <div className="post-name">{searchAIArticles[0]?.title}</div>
                <SearchAIArticles aiArticles={searchAIArticles} />
              </>
            )}
          </div>
          <AdSection>
            {isBusyAd && <Loader>Loading...</Loader>}
            {!isBusyAd && (
              <YahooAds
                yahooads={yahooads.slice(0, 1)}
                onClick={handleOnAdClick}
              />
            )}
          </AdSection>
        </div>
      </Container>
    </Wrapper>
  )
}
