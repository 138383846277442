import getClient from './client'

export default async function postAdClick(
  siteID,
  currentPageUrl,
  referrerUrl,
  type,
  adPos,
) {
  const { data } = await getClient().get(`adpartners/${siteID}/postAdClick`, {
    params: {
      url: currentPageUrl,
      referrer: referrerUrl,
      t: type,
      pos: adPos + 1,
    },
  })

  const result = data || []

  return result
}
