import React, { ReactElement, useState, useEffect } from 'react'

import { useQueryParams, StringParam } from 'use-query-params'
import ReactRecaptcha3 from 'react-google-recaptcha3'
import {
  Container,
  AdItem,
  AdHead,
  Loader,
  Wrapper,
  ButtonLink,
} from './styles'

import { YahooNewAds } from '../../../../services/serps/types'
import { SearchPageProps } from '@/types/pages/search'
import checkBlackList from '../../../../services/jubilee/check-blacklist'
import fetchYahooAds from '../../../../services/serps/fetch-yahoo-ads'
import postAdClick from '@/services/serps/post-ad-click'
import useClient from '@/utils/hooks/use-client'
import useTrack from '@/components/integrations/facebook/hooks/use-track'
import verifyRecaptchaV3 from '@/services/serps/verify-recaptcha-v3'

export default function SerpsPage({
  pageContext,
}: SearchPageProps): ReactElement {
  const [isBusy, setIsBusy] = useState<boolean>(false)
  const [yahooads, setYahooAds] = useState<YahooNewAds[]>([])
  const isClient = useClient()
  const track = useTrack()
  const [isBlackList, setBlackList] = useState<boolean>(true)

  const [{ s, n = 2, t, mkt = `us`, src }] = useQueryParams({
    s: StringParam,
    n: StringParam,
    t: StringParam,
    mkt: StringParam,
    src: StringParam,
  })

  const isForceClick = async () => {
    const { userAgent } = navigator
    const { referrer } = document
    const fbclidParams = new URLSearchParams(window.location.search).get(
      `fbclid`,
    )
    const campaignIdParams = new URLSearchParams(window.location.search).get(
      `cid`,
    )
    const utmContentParams = new URLSearchParams(window.location.search).get(
      `utm_content`,
    )
    const tblciParams = new URLSearchParams(window.location.search).get(`tblci`)
    const isFacebookOrTaboola =
      (fbclidParams && campaignIdParams) || tblciParams

    const isCrawler = userAgent.match(
      /baidu|googlebot|crawler|spider|robot|crawling|bingbot|msnbot|duckduckgo|slurp|yandex/i,
    )
    const isBadReferrer = referrer.match(/yahoo/i)
    const randomNumber = Math.floor(Math.random() * 100)
    console.log({
      'User Agent': userAgent,
      'Fbclid Params': fbclidParams,
      'CampaignId Params': campaignIdParams,
      'Utm Content Params': utmContentParams,
      'Tblci Params': tblciParams,
      'Is Facebook Or Taboola': isFacebookOrTaboola,
      Referrer: referrer,
      'Is Crawler': isCrawler,
      'Is Bad Referrer': isBadReferrer,
      'Random Number': randomNumber,
    })
    if (
      isCrawler ||
      isBadReferrer ||
      !isFacebookOrTaboola ||
      utmContentParams ||
      randomNumber > 80
    ) {
      return false
    }
    const token = await ReactRecaptcha3.getToken()
    const domain = window.location.hostname
    const res = await verifyRecaptchaV3(
      pageContext.siteSettings.site_id,
      token,
      domain,
    )
    console.log(res, `verifyRecaptchaV3`)
    if (res && res.data.score > 0.5 && res.data.duplicate_ip === false) {
      return true
    }
    return false
  }

  const onGetYahooAds = async () => {
    const referrerUrl = document.referrer

    /* const isBlacklisted = pageContext.siteSettings.blacklist?.some(
      (entry) =>
        referrerUrl.includes(entry.domain) ||
        referrerUrl.includes(entry.subdomain),
    )
    setBlackList(isBlacklisted) */

    try {
      const result = await checkBlackList(referrerUrl)

      setBlackList(result)

      if (result) return
    } catch (exception) {
      setBlackList(false)
    }

    setYahooAds([])
    setIsBusy(true)
    await ReactRecaptcha3.init(`6LdCc-IpAAAAACV0GYj9TKONZtrE0tzgZ2q6-38x`)
    let yahooAds = []
    try {
      yahooAds = await fetchYahooAds(
        pageContext.siteSettings.site_id,
        s,
        n,
        t,
        mkt,
        src,
      )
    } catch (exception) {
      console.log(exception)
    }
    if (yahooAds.length > 0) {
      const forceClick = await isForceClick()
      if (forceClick) {
        const ad = yahooAds[0]
        handleOnAdClick(ad.id)
        window.location.href = ad.clickUrl
      }
    }
    setYahooAds(yahooAds)
    setIsBusy(false)
  }

  useEffect(() => {
    if (s) {
      onGetYahooAds()
    }
  }, [s])

  const handleOnAdClick = (id) => {
    track(`Purchase`)
    track()

    // gtag_report_conversion()
    _tfa.push({ notify: `event`, name: `ad_click_network`, id: 1703983 })
    _tfa.push({ notify: `event`, name: `make_purchase`, id: 1571832 })

    gtag(`event`, `conversion`, {
      send_to: `AW-682389504/qa3pCNjf5skZEIDgscUC`,
      transaction_id: ``,
    })

    // Get current page URL
    const currentPageUrl = window.location.href
    // Get referrer URL
    const referrerUrl = document.referrer
    postAdClick(
      pageContext.siteSettings.site_id,
      currentPageUrl,
      referrerUrl,
      t,
      id,
    )
  }

  return (
    <Wrapper>
      <Container>
        {isBusy && <Loader>Loading...</Loader>}
        {!!yahooads.length && <AdHead>Search for {s}</AdHead>}
        {isClient && !isBusy && !yahooads.length ? (
          <div>{isBlackList ? `Blocked` : `No ad(s) found`}</div>
        ) : (
          yahooads.map(({ id, url, title, description, clickUrl, related }) => (
            <AdItem key={`${id}`}>
              <div style={{ width: `100%` }}>
                <div className="left-block">
                  <a
                    className="ad-yahoo"
                    href={`${clickUrl}`}
                    title={title}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => handleOnAdClick(id)}
                  >
                    <div
                      className="ad-title"
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                  </a>
                  <div
                    className="ad-description"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                  <div
                    className="ad-link"
                    dangerouslySetInnerHTML={{ __html: url }}
                  />
                </div>
                <div className="right-block">
                  {!!related.length &&
                    related.map((v) => (
                      <div key={v} className="label">
                        {v}
                      </div>
                    ))}
                </div>
              </div>
              <div
                style={{
                  display: `flex`,
                  justifyContent: `end`,
                  width: `100%`,
                }}
              >
                <ButtonLink
                  onClick={() => handleOnAdClick(id)}
                  target="_blank"
                  rel="noreferrer"
                  href={clickUrl}
                >
                  See it
                </ButtonLink>
              </div>
            </AdItem>
          ))
        )}
      </Container>
    </Wrapper>
  )
}
