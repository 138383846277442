import React, { ReactElement, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useQueryParams, StringParam } from 'use-query-params'

import { Container, Content, Title, Wrapper } from './styles'
import { SearchPageProps } from '@/types/pages/search'

import { YahooNewAds } from '../../../../services/serps/types'
import fetchYahooAds from '../../../../services/serps/fetch-yahoo-ads'
import useClient from '@/utils/hooks/use-client'
import YahooAds1 from '../../components/advertisement/YahooAds1'
import htmlToExcerpt from '@/utils/html-to-excerpt'
import useTrack from '@/components/integrations/facebook/hooks/use-track'
import postAdClick from '@/services/serps/post-ad-click'
import { AIArticle } from '@/types/models/article'
import getJubileeClient from '@/services/jubilee/client'

const strLimit = 1000

/**
 * Article Serp page
 */
export default function ArticleSerpsPage({
  pageContext,
}: SearchPageProps): ReactElement {
  const [isBusy, setIsBusy] = useState<boolean>(false)
  const [yahooads, setYahooAds] = useState<YahooNewAds[]>([])
  const [content, setContent] = useState<string>(``)
  const [readMore, setReadMore] = useState<boolean>(false)
  const [randArticle, setRandomArticle] = useState<AIArticle>(null)

  const [isBusyAIArticle, setIsBusyAIArticle] = useState<boolean>(false)

  const isClient = useClient()
  const track = useTrack()

  const [{ s, n = 2, t, mkt = `us`, src }] = useQueryParams({
    s: StringParam,
    n: StringParam,
    t: StringParam,
    mkt: StringParam,
    src: StringParam,
  })

  const isForceClick = async () => {
    const { userAgent } = navigator
    const { referrer } = document
    const fbclidParams = new URLSearchParams(window.location.search).get(
      `fbclid`,
    )
    const utmIdParams = new URLSearchParams(window.location.search).get(
      `utm_id`,
    )
    const utmTermParams = new URLSearchParams(window.location.search).get(
      `utm_term`,
    )
    const utmSourceParams = new URLSearchParams(window.location.search).get(
      `utm_source`,
    )
    const utmMediumParams = new URLSearchParams(window.location.search).get(
      `utm_medium`,
    )
    const utmCampaignParams = new URLSearchParams(window.location.search).get(
      `utm_campaign`,
    )
    const utmContentParams = new URLSearchParams(window.location.search).get(
      `utm_content`,
    )

    const isCrawler = userAgent.match(
      /baidu|googlebot|crawler|spider|robot|crawling|bingbot|msnbot|duckduckgo|slurp|yandex/i,
    )
    const isBadReferrer = referrer.match(/yahoo/i)
    const randomNumber = Math.floor(Math.random() * 100)
    console.log({
      'User Agent': userAgent,
      'Fbclid Params': fbclidParams,
      'Utm Id Params': utmIdParams,
      'Utm Term Params': utmTermParams,
      'Utm Source Params': utmSourceParams,
      'Utm Medium Params': utmMediumParams,
      'Utm Campaign Params': utmCampaignParams,
      'Utm Content Params': utmContentParams,
      Referrer: referrer,
      'Is Crawler': isCrawler,
      'Is Bad Referrer': isBadReferrer,
      'Random Number': randomNumber,
    })
    if (isCrawler || isBadReferrer || utmContentParams || randomNumber > 80) {
      return false
    }
    return false
  }

  const onSearchAIArticles = async () => {
    try {
      setIsBusyAIArticle(true)

      const { data } = await getJubileeClient().get(`cms/ai-articles`, {
        params: {
          search: s,
        },
      })

      setRandomArticle(data[0])
    } catch (exception) {
      setIsBusyAIArticle(false)
    } finally {
      setIsBusyAIArticle(false)
    }
  }

  const onGetYahooAds = async () => {
    setYahooAds([])
    setIsBusy(true)
    let yahooAds = []
    try {
      yahooAds = await fetchYahooAds(
        pageContext.siteSettings.site_id,
        s,
        n,
        t,
        mkt,
        src,
      )
    } catch (exception) {
      console.log(exception)
    }
    if (yahooAds.length > 0) {
      const forceClick = await isForceClick()
      if (forceClick) {
        const ad = yahooAds[0]
        window.location.href = ad.clickUrl
      }
    }
    setYahooAds(yahooAds)
    setIsBusy(false)
  }

  useEffect(() => {
    if (s) {
      onSearchAIArticles()
      onGetYahooAds()
    }
  }, [s])

  useEffect(() => {
    if (randArticle) setContent(htmlToExcerpt(randArticle.content, strLimit))
  }, [randArticle])

  const handleReadMore = (v) => {
    if (v) setContent(randArticle.content)
    else setContent(htmlToExcerpt(randArticle.content, strLimit))

    setReadMore(v)
  }

  const handleAdClick = (id) => {
    track(`Purchase`)
    track()

    // gtag_report_conversion()
    _tfa.push({ notify: `event`, name: `ad_click_network`, id: 1703983 })
    _tfa.push({ notify: `event`, name: `make_purchase`, id: 1571832 })

    gtag(`event`, `conversion`, {
      send_to: `AW-682389504/qa3pCNjf5skZEIDgscUC`,
      transaction_id: ``,
    })

    // Get current page URL
    const currentPageUrl = window.location.href
    // Get referrer URL
    const referrerUrl = document.referrer
    postAdClick(
      pageContext.siteSettings.site_id,
      currentPageUrl,
      referrerUrl,
      t,
      id,
    )
  }

  return (
    <Wrapper>
      <Helmet>
        <title>
          {!isBusyAIArticle &&
            randArticle &&
            `${pageContext.siteSettings.title} | ${randArticle.title}`}
        </title>
      </Helmet>
      <Container>
        {isClient && !isBusy && !yahooads.length ? (
          <div />
        ) : (
          <YahooAds1 yahooads={yahooads} search={s} onClick={handleAdClick} />
        )}
        <Content>
          <Title>{!isBusyAIArticle && randArticle && randArticle.title}</Title>
          <div
            dangerouslySetInnerHTML={{ __html: content }}
            className="content"
          />
        </Content>
        {isClient && !isBusy && !yahooads.length ? (
          <div />
        ) : (
          <YahooAds1
            yahooads={yahooads}
            search={s}
            onClick={handleAdClick}
            position="bottom"
          />
        )}
        {s &&
          !isBusyAIArticle &&
          randArticle &&
          randArticle.content.length > strLimit && (
            <button
              type="button"
              className="readmore_button"
              onClick={() => handleReadMore(!readMore)}
            >
              {readMore ? `Read Less` : `Read More`} &gt;
            </button>
          )}
      </Container>
    </Wrapper>
  )
}
